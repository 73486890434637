import { Injectable } from '@angular/core';
import { HttpClient } from '../../_services/index';
import { AppConfig } from '../../_helpers/config';

@Injectable({
  providedIn: 'root'
})
export class NcdService {
  GetInstitutionsReportsearch(StateId: any, term: string) {
      throw new Error('Method not implemented.');
  }

  config: any;
  constructor(private http: HttpClient, config: AppConfig) { this.config = config }

  GetIDProofType() {
    return this.http.get("/NCDPatient/IDProofType");
  }
  GetCbacMst(PatientId) {
    return this.http.get("/NCDPatient/GetCbacMst?PatientId=" + PatientId);
  }
  GetRank(qid, ansid) {
    return this.http.get("/NCDPatient/GetRank?qid=" + qid + "&ansid=" + ansid);
  }
  AddNCDPatientDetail(data: any) {

    return this.http.post("/NCDPatient", data);
  }
  NCDPatientlist(data: any) {

    return this.http.post("/NCDPatient/List", data);
  }

  SuspectedNCDPatient(data: any) {

    return this.http.post("/ncdpatient/SuspectedNCDPatientList", data);
  }
  GetNCDPatientByID(id) {
    return this.http.get("/NCDPatient/GetNCDPatientById/" + id);
  }
  GetGetFamilyhead(id) {
    return this.http.get("/NCDPatient/GetFamilyhead/?id=" + id);
  }
  SaveCbac(data: any) {

    return this.http.post("/NCDPatient/SaveCbac", data);
  }
  SaveUpadteScreeningHypertension(data: any) {

    return this.http.post("/NCDPatient/SaveUpadteScreeningHypertension", data);
  }
  SaveUpadteScreeningDiabetes(data: any) {

    return this.http.post("/NCDPatient/SaveUpadteScreeningDiabetes", data);
  }
  GetScreeningHypertension(id) {
    return this.http.get("/NCDPatient/GetScreeningHypertension?id=" + id);
  }
  GetScreeningDiabetes(id) {
    return this.http.get("/NCDPatient/GetScreeningDiabetes?id=" + id);
  }
  GetVitalhistory(id) {
    return this.http.get("/NCDPatient/GetVitalhistory?id=" + id);
  }
  Getwaistcircumference() {
    return this.http.get('/NCDPatient/Getwaistcircumference');
  }
  Savevitals(data: any) {

    return this.http.post('/NCDPatient/Savevitals', data);
  }
  // Report

  GetEnrollmentstatus(data) {
    return this.http.post('/NcdReport/GetEnrollmentstatus', data);
  }

  GetSuspectedNCDPatient(data) {
    return this.http.post('/NcdReport/GetSuspectedNCDPatientAdmin', data);
  }

  GetFindingsDetailsList() {
    return this.http.get('/NCDPatient/GetDisease_Location?ScreeningID=3');
  }

  GetOralCavityList() {
    return this.http.get('/NCDPatient/CodeValueDescriptions?CodeTypeId=1');
  }

  GetChronicTraumaList() {
    return this.http.get('/NCDPatient/CodeValueDescriptions?CodeTypeId=2');
  }
  sitList() {
    return this.http.get("/NCDPatient/CodeValueDescriptions?CodeTypeId=3");
  }

  oralCancerXmlTable(id) {
    return this.http.get("/NCDPatient/GetScreeningOralCancerDetails?OralCancerId="+id);
  }

  oralCancerDetails(id) {
    return this.http.get("/NCDPatient/GetScreeningOralCancerData?PatientId="+id);
  }

  saveScreeningOralCancer(data) {
    return this.http.post("/NCDPatient/SaveScreeningOralCancer", data);
  }

  GetRightLeftDropdownList() {
    return this.http.get("/NCDPatient/GetDisease_Location?ScreeningID=4");
  }

  axiallyList() {
    return this.http.get("/NCDPatient/CodeValueDescriptions?CodeTypeId=4");
  }

  supraclavicularList() {
    return this.http.get("/NCDPatient/CodeValueDescriptions?CodeTypeId=5");
  }

  brestCancerXmlTable(id) {
    return this.http.get("/NCDPatient/GetScreeningBreastCancerDetails?BreastCancerId="+id);
  }

  saveScreeningBrestCancer(data) {
    return this.http.post("/NCDPatient/SaveScreeningBreastCancer", data);
  }

  brestCancerDetails(id) {
    return this.http.get("/NCDPatient/GetScreeningBreastCancer?PatientId="+id);
  }

  bimanualExaminationList() {
    return this.http.get("/NCDPatient/CodeValueDescriptions?CodeTypeId=6");
  }

  SaveCeervical(data) {
    return this.http.post("/NCDPatient/SaveScreeningCervicalCancer", data);
  }

  CeervicalDetails(id) {
    return this.http.get("/NCDPatient/GetScreeningCervicalCancer?PatientId="+id);
  }

  SaveScreeningTB(data: any) {

    return this.http.post("/NCDPatient/SaveScreeningTB", data);
  }

  GetScreeningTB(id) {
    return this.http.get("/NCDPatient/GetScreeningTB?PatientId="+id);
  }

  GetGenerateOtp (adherId) {
    return this.http.get('/NCDPatient/GetGenerateOtp?AadharNo=' + adherId);
  }

  GetVerifyOTP(otp, TxnID, MobileNo) {
    return this.http.get('/NCDPatient/GetVerifyOTP?OTP=' + otp + '&TxnID=' + TxnID + '&MobileNo=' + MobileNo);
  }
  UpdateMobileNoPHRAddress(HealthID, PHR) {
    return this.http.get('/NCDPatient/UpdateMobileNoPHRAddress?HealthIDNumber=' + HealthID + '&PHRAddress=' + PHR);
  }
  GetGenerateMobileOTP (MobileNo, TxnID) {
    return this.http.get('/NCDPatient/GetGenerateMobileOTP?MobileNo=' + MobileNo + '&TxnID=' + TxnID);
  }

  GetVerifyMobileOTP (otp, TxnID) {
    return this.http.get('/NCDPatient/GetVerifyMobileOTP?OTP=' + otp + '&TxnID=' + TxnID);
  }

  GetCreateHealthIdWithPreVerified (PHR, TxnID, PatientID, PatientType) {
    return this.http.get('/NCDPatient/GetCreateHealthIdWithPreVerified?PHR=' + PHR + '&TxnID=' + TxnID + '&PatientID=' + PatientID+'&PatientType='+PatientType);
  }

  GetInit(HealthID, AuthMode) {
    return this.http.get('/NCDPatient/GetInit?HealthID=' + HealthID + '&AuthMode=' + AuthMode);
  }
  GetConfirmWithAadhaarOTP(OTP, TxnID, PatientID) {
    return this.http.get('/NCDPatient/GetConfirmWithAadhaarOTP?OTP=' + OTP + '&TxnID=' + TxnID + '&PatientID=' + PatientID);
  }
  GetProfile(Token, PatientID, PatientType) {
    return this.http.get('/NCDPatient/GetProfile?Token=' + Token + '&PatientID=' + PatientID+'&PatientType='+PatientType);
  }
  GetNCDDashboardData(data) {
    return this.http.post('/NcdReport/NCDDashboard', data);
  }
  GetNCDScoreCalculation(data) {
    return this.http.post('/NcdReport/NCDScore', data);
  }
  GetInstituteDetail() {
    return this.http.get("/NCDPatient/GetInstituteDetail");
  }
}
